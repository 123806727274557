.custom-disabled {
  background-color: #d5d5d5;
  color: #525252;
}

.custom-disabled:hover {
  background-color: #d5d5d5;
  color: #525252;
  cursor: pointer;
}

.custom-active {
  background-color: #525252;
  color: white;
}
.custom-active:hover {
  background-color: #525252;
  color: white;
}

.custom-pagination:hover {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .custom-header {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
  }
  .custom-title-header {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
}

.update-button {
  background-color: #525252;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
